<template>
  <div>
    <validation-observer ref="form">

      <empty-card
        :title="$t('business.edit-profile')"
      >
        <template slot="title">
          <div
            class="d-flex align-items-center"
          >
            <b-button
              variant="dark"
              :disabled="submitting"
              @click="submit()"
            >
              <span :class="{'pr-1': submitting}">{{ $t('forms.save') }}</span>
              <b-spinner
                v-if="submitting"
                small
              />
            </b-button>
          </div>
        </template>
        <div class="item-general-form mt-2">
          <div>
            <b-row class="align-items-center">
              <b-col
                v-if="error"
                md="12"
              >
                <error-alert :error="error" />
              </b-col>
              <b-col
                md="6"
                class="mb-1"
              >
                <ok-text-input
                  v-model="form.name"
                  size="md"
                  :label="$t('fields.name')"
                  is-required
                />
              </b-col>
              <b-col
                md="6"
                class="mb-1"
              >
                <ok-text-input
                  v-model="form.email"
                  size="md"
                  :label="$t('fields.email')"
                  is-required
                />
              </b-col>
              <b-col
                md="6"
                class="mb-1"
              >
                <ok-text-input
                  size="md"
                  :label="$t('fields.password')"
                >
                  <password-input
                    v-model="form.password"
                    size="md"
                  />
                </ok-text-input>
              </b-col>
              <b-col
                md="6"
                class="mb-1"
              >
                <ok-text-input
                  size="md"
                  :label="$t('business.locales')"
                >
                  <localization-language-select
                    v-model="form.locales"
                    disabled
                    with-ui
                    multiple
                  />
                </ok-text-input>
              </b-col>
            </b-row>
          </div>
        </div>
      </empty-card>
    </validation-observer>

  </div>
</template>

<script>
import {
  BButton, BSpinner, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ErrorHandler from '@/utils/error-handler'
import EmptyCard from '@/@bya3/components/cards/EmptyCard'
import AuthApi from '@/api/auth'
import pick from 'lodash/pick'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import PasswordInput from '@/@bya3/components/inputs/PasswordInput'
import { mapGetters } from 'vuex'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import LocalizationLanguageSelect from '@/views/store/inputs/StoreLanguageSelect'

export default {
  name: 'ProcessBusinessInfoPage',
  components: {
    LocalizationLanguageSelect,
    ErrorAlert,
    EmptyCard,
    ValidationObserver,
    OkTextInput,
    PasswordInput,
    BButton,
    BSpinner,
    BRow,
    BCol,
  },
  data() {
    return {
      submitting: false,
      form: {},
      error: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.form = {
        name: this.user.name,
        email: this.user.email,
        locales: this.user?.locales ?? [],

      }
    },
    async submit() {
      await new Promise(resolve => setTimeout(resolve, 200))
      const valid = await (this.$refs.form.validateWithInfo())
      this.error = null
      if (!valid.isValid) {
        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
        return
      }

      const keys = ['name', 'email', 'password']
      const general = pick(this.form, keys)
      general.id = this.user.id
      this.submitting = true
      AuthApi.update(general)
        .then(response => {
          this.$notifySuccess(response.message)
          this.$store.commit('auth/UPDATE_USER', pick(this.form, ['name', 'email']))
        })
        .catch(error => { this.error = error })
        .finally(() => { this.submitting = false })
    },
  },
}
</script>
